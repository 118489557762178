import React from "react";
import Header from "./components/header";
import { useSelector } from "react-redux";
import Root from "./pages/root";
import Footer from "./components/footer";
import ScrollToTop from "./scroll";

function App() {
  const { colors } = useSelector((state) => state.theme);

  return (
    <div
      style={{
        width: "100%",
        background: colors.background,
      }}
    >
      <ScrollToTop />
      <Header />
      <Root />
      <Footer />
    </div>
  );
}

export default App;
