/** @format */

import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import logo from "../header/image/logo.png";

function Footer() {
  const { t } = useTranslation();
  const { colors } = useSelector((state) => state.theme);
  return (
    <footer
      style={{
        color: colors.text3,
        background: colors.topHeaderColor,
      }}
      className="footer-distributed"
    >
      <div className="footer-left">
        <div className="logo_box">
          <img src={logo} alt="bkpi_logo" />
          <h3>BKPi</h3>
        </div>

        <p className="footer-links">
          {t("footer.navLink", { returnObjects: true }).map((item, index) => (
            <Link
              key={index}
              style={{
                color: colors.text3,
              }}
              to={item.link}
              className={index === 0 ? "link-1" : ""}
            >
              {item.title}
            </Link>
          ))}
        </p>

        <p className="footer-company-name">BKPi 2024 Test</p>
      </div>

      <div className="footer-center">
        <div>
          <i className="fa fa-map-marker"></i>
          <p>{t("footer.address", { returnObjects: true })[0].title}</p>
        </div>

        <div>
          <i className="fa fa-phone"></i>
          <p>{t("footer.address", { returnObjects: true })[1].title}</p>
        </div>

        <div>
          <i className="fa fa-envelope"></i>
          <p>
            <Link
              style={{
                color: colors.text3,
              }}
              to={`mailto:${
                t("footer.address", { returnObjects: true })[2].title
              }`}
            >
              {t("footer.address", { returnObjects: true })[2].title}
            </Link>
          </p>
        </div>
      </div>

      <div className="footer-right">
        <p className="footer-company-about">
          <span>{t("footer.aboutCompony.title")}</span>
          {t("footer.aboutCompony.description")}
        </p>
      </div>
    </footer>
  );
}

export default Footer;
