import { TOGGLE_THEME } from "../actions/themeType";

const initialTheme = localStorage.getItem("theme") || "light";

const initialState = {
  theme: initialTheme,
  colors: {
    background: initialTheme === "light" ? "#fdfff5" : "#2a4658",
    text1: initialTheme === "light" ? "#13529A" : "#61E6EE",
    buttonColor: initialTheme === "light" ? "white" : "#2EC3CC",
    buttonText1: initialTheme === "light" ? "black" : "#2a4658",
    topHeaderColor: initialTheme === "light" ? "white" : "#576b7a",
    text3: initialTheme === "light" ? "#36454f" : "#f8f8ff",
    blurFilter:
      initialTheme === "light"
        ? "rgba(0, 0, 0, 0.1)"
        : "rgba(256, 256, 256, 0.1)",
    boxShadow:
      initialTheme === "light"
        ? "rgba(34, 60, 80, 0.1)"
        : "rgba(255, 255, 255, 0.2)",
    iconFilter:
      initialTheme === "light"
        ? "invert(51%) sepia(50%) saturate(654%) hue-rotate(132deg) brightness(94%) contrast(92%)"
        : "invert(74%) sepia(81%) saturate(308%) hue-rotate(132deg) brightness(97%) contrast(92%)",
    textWhite: "#fff",
  },
};

const themes = {
  light: {
    background: "#f6fff8",
    text1: "#13529A",
    buttonColor: "white",
    buttonText1: "black",
    topHeaderColor: "white",
    boxShadow: "rgba(34, 60, 80, 0.1)",
    textWhite: "#fff",
    text3: "#36454f",
    blurFilter: "rgba(0, 0, 0, 0.1)",
    iconFilter:
      "invert(51%) sepia(50%) saturate(654%) hue-rotate(132deg) brightness(94%) contrast(92%)",
  },
  dark: {
    background: "#2a4658",
    text1: "#61E6EE",
    buttonColor: "#2EC3CC",
    buttonText1: "#2a4658",
    topHeaderColor: "#576b7a",
    boxShadow: "rgba(255, 255, 255, 0.2)",
    textWhite: "#fff",
    text3: "#f8f8ff",
    blurFilter: "rgba(256, 256, 256, 0.1)",
    iconFilter:
      "invert(79%) sepia(65%) saturate(441%) hue-rotate(141deg) brightness(98%) contrast(90%)",
  },
};

const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_THEME:
      const newTheme = state.theme === "light" ? "dark" : "light";

      localStorage.setItem("theme", newTheme);

      return {
        ...state,
        theme: newTheme,
        colors: themes[newTheme],
      };
    default:
      return state;
  }
};

export default themeReducer;
